/* Provide sufficient contrast against white background */
body {
  background-color: #f9fafb !important;
}
html.font-reset{
  font-size: 16px;
}

.layout {
  display: flex;
  height: 100vh;
  flex-direction: column;
  margin: 0;
  overflow-x: hidden;
}

.content {
  flex-grow: 1;
}

.ui.menu.top-nav {
  min-height: 4em;
  height:64px;
}

.footer {
  padding: 1.8em 0em;
  min-height: 12em;
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.toast-container {
  z-index: 1500 !important;
}

.ui.icon.message>.content {
  padding-bottom: unset !important;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.pickerSize {
  width: 2em;
}

.btn-grey {
  background-Color: #e8e8e8;
  color: rgba(0, 0, 0, .6);
  height: 3.4em !important;
}

.one.wide.computer.four.wide.mobile.column {
  padding-left: 1.2em;
}

.mobile {
  padding-top: 0.4em;
}

.stempel-nopadding-bottom {
  padding-bottom: unset !important;
}

.stempel-bordertop {
  border-top: 3px solid rgb(0 148 170);
  margin-top: 0.8em !important;
  padding-top: 0.4em !important;
}

.noLeftBorder {
  border-left: unset;
}

.timeinput-block {
  display: flex;
  align-items: center;
  position: relative;
}

.kontierung-block {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 1px;
}

.block {
  display: flex;
  align-items: center;
  margin: .7em 0em .7em 0em;
  position: relative;
}

.fill-up {
  margin-left: -0.1em !important;
  width: -webkit-fill-available !important;
}

.fill-dropdown {
  width: 30em;
  margin-left: 1em;
}

.fill-time {
  width: 14em;
  margin-left: 1em;
}

.ui.input.noLeftBorder>input {
  border-left: unset !important;
}

.left-right-padding {}

.ui.input.left-right-padding>input {
  padding-left: 0.3em !important;
  padding-right: 0.3em !important;
}

.timeinput-label {
  margin-right: 10px;
}

.brand {
  background-image: url(dev.png);
  background-repeat: repeat;
  background-size: 100px;
}

.custom-segment {
  max-width: 70%;
  margin: auto !important;
}

.errorBorder {
  border: 0.5px solid red !important;
}

.fehler {
  border-top: 1px solid red !important;
  border-bottom: 1px solid red !important;
  background: white;
}

.fehler-first {
  border-top: 1px solid red !important;
  border-bottom: 1px solid red !important;
  border-left: 1px solid red !important;
  background: white;
}

.fehler-last {
  border-top: 1px solid red !important;
  border-bottom: 1px solid red !important;
  border-right: 1px solid red !important;
  background: white;
}

.fehlerFeiertag {
  border-top: 1px solid red !important;
  border-bottom: 1px solid red !important;
  background: lightblue;
}

.fehlerSamstag {
  border-top: 1px solid red !important;
  border-bottom: 1px solid red !important;
  background: cornsilk;
}

.fehlerSonntag {
  border-top: 1px solid red !important;
  border-bottom: 1px solid red !important;
  background: oldlace;
}

.fehlerFeiertag-first {
  border-top: 1px solid red !important;
  border-bottom: 1px solid red !important;
  border-left: 1px solid red !important;
  background: lightblue;
}

.fehlerSamstag-first {
  border-top: 1px solid red !important;
  border-bottom: 1px solid red !important;
  border-left: 1px solid red !important;
  background: cornsilk;
}

.fehlerSonntag-first {
  border-top: 1px solid red !important;
  border-bottom: 1px solid red !important;
  border-left: 1px solid red !important;
  background: oldlace;
}

.fehlerFeiertag-last {
  border-top: 1px solid red !important;
  border-bottom: 1px solid red !important;
  border-right: 1px solid red !important;
  background: lightblue;
}

.fehlerSamstag-last {
  border-top: 1px solid red !important;
  border-bottom: 1px solid red !important;
  border-right: 1px solid red !important;
  background: cornsilk;
}

.fehlerSonntag-last {
  border-top: 1px solid red !important;
  border-bottom: 1px solid red !important;
  border-right: 1px solid red !important;
  background: oldlace;
}

.feiertag {
  background: lightblue;
}

.samstag {
  background: cornsilk;
}

.sonntag {
  background: oldlace;
}

.frei {
  background: lightyellow;
}

.abwesend-offen {
  background: deepskyblue;
}

.abwesend {
  background: lightgreen;
}

.arbeitsTag {
  background: white;
}

.n {
  background: linear-gradient(135deg, lightgrey, white);
}

.betriebsfrei {
  background: grey;
}

.kein-arbeitstag {
  background: lightgrey;
  border: 1px solid #adadad85;
}

.tagesstempel-container {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  gap: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

img.tagesstempel-img {
  height: 18px;
  display: block;
}

img.tagesstempel-img:nth-child() .ui.basic.segment {
  height: 3em;
}

.top-nav.ui.menu {
  border-radius: 0;
}

.menu-dropdown {
  max-width: 15em;
  min-width: 13em;
}

.center {
  text-align: center;
}

.table-button {
  width: auto !important;
  height: 20px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.content {
  padding-bottom: 5em;
}

.container.fit-content {
  width: 100vw;
  max-width: 1600px;
}

.ui.button {
  height: 2.5em;
  width: 10em;
}

.taetigkeitbutton {
  height: 2.5em !important;
  min-width: 8em;
  width: auto;
  margin-right: 0.5em !important;
  margin-top: 0.2em !important;
  margin-bottom: 0.2em !important;
}

.taetigkeiticonbutton {
  height: 2.5em !important;
  width: 3.5em !important;
  margin-right: 0.5em !important;
  margin-top: 0.2em !important;
  margin-bottom: 0.2em !important;
  padding-left: 0.4em !important;
  padding-right: unset !important;
}

.taetigkeiticon {
  margin-top: -0.5em !important;
  height: 2em;
  max-width: 2.5em;
  width: auto;
  margin-right: 0.5em;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.tabletaetigkeiticon {
  margin: 0 0.2em 0 0 !important;
  height: 1.2em !important;
  max-width: 2.5em !important;
  width: auto !important;
}

.taetigkeitchosen {
  border: solid 3px black !important;
}

.taetigkeitchosenrow {
  border: solid 3px black !important;
}

.taetigkeiticonbutton.taetigkeitchosen {
  padding-top: 0.4em !important;
}

.taetigkeiticonbutton.taetigkeitchosenrow {
  padding-top: 0.5em !important;
}

.taetigkeitbutton.taetigkeitchosen {
  height: 2em !important;
}

.table-span {
  padding-right: unset !important;
}

.table-checkbox {
  padding-left: 0.3em !important;
}

.ressourcen-cell-desktop {
  padding: 8px 0;
}

.ressourcen-cell-desktop:first-child {
  border-right: 1px solid rgba(34, 36, 38, 0.1);
}

.ressourcen-cell-desktop>p {
  padding: 2px;
  background-color: white;
  margin: 0;
  display: inline-block;
}

table.abwesenheitenTabelle th.currentDay,
.mobile-abwesenheit.currentDay {
  background-color: #fad64e !important;
  color: black !important;
}

.ui.segment.currentWeek {
  border: 4px solid #0094aa;
}

.nowrap {
  white-space: nowrap !important;
}

.ui.button.primary {
  background-color: rgb(27 28 29) !important;
  color: rgb(255 255 255) !important;
}

.ui.button.secondary {
  background-color: rgb(0 148 170) !important;
  color: rgb(255 255 255) !important;
}

.ui.segment:not(.attached),
.border-top,
.border-top td {
  border-top: .2em solid #0094aa !important;
}

.ui.segment .ui.segment {
  border-top: unset !important;
}

div.ui.segment,
.ui.segments .segment {
  font-size: 1em;
}

.ui.table th {
  background: #1b1c1d !important;
  color: #fff !important;
}

.table-header-extend {
  white-Space: nowrap;
}

.align-tablerow-right {
  text-align: right !important;
}

.align-row-right {
  display: block !important;
  text-align: right !important;
}

.align-row-center {
  display: block !important;
  text-align: center !important;
}

/*
-------------------------------------------------
LEGENDE 
-------------------------------------------------
*/

.legend-grid{
  display:grid; 
  gap:.5rem 2rem;  
  grid-template-columns: repeat(12, 1fr);
}
.legend-grid > * {
  grid-column: span 3;
}
@media screen and (max-width:768px){
  .legend-grid > * {
    grid-column: span 4; 
  }
}
.legend-grid > .new-row{
  grid-column: 1 / -1;
  height:1em;
}
.legend-grid > .legend-bg{ 
  display:flex; 
  align-items: center;
  grid-column: span 3;
  gap:1em;
}
@media screen and (max-width:550px){
  .legend-grid > .legend-bg {
    grid-column: span 6; 
    justify-content: start;
  }
}
.legend-bg > *:first-child{
  width:1.5rem;
  flex-shrink: 0;
  height:1.5rem;
  border:1px solid black;
}
.legend-bg>.img-container {
  border:none;
  background-repeat: no-repeat;
  background-size: contain;
}
.legend-bg > div.fehler-box{
  border:2px solid red;
}

.legend-box {
  border: solid black 1px;
  width: 5px !important;
  margin-top: 13px !important;
  margin-bottom: 15px !important;
  padding: 10px !important;
}

.legend-box-fehler {
  border: solid red 1px;
  width: 5px !important;
  margin-top: 13px !important;
  margin-bottom: 15px !important;
  padding: 10px !important;
}

.legend-box-img {
  background-repeat: no-repeat;
  background-size: contain;
  width: 5px !important;
  margin-top: 13px !important;
  margin-bottom: 15px !important;
  padding: 10px !important;
}
/* 
-------------------------------------------------

-------------------------------------------------
*/ 
.row-button {
  height: 37px !important;
  width: 30px !important;
  margin: unset !important;
  padding: unset !important;
  margin-left: 0.3em !important;
}

.row-icon {
  margin-left: 0.3em !important;
  color: black !important;
}

.row-icon.full {
  color: rgb(0 148 170) !important;
}

.prev-next {
  height: 1.5em !important;
  width: 1.3em !important;
  margin: unset !important;
  padding: unset !important;
}

.dirty-warning {
  float: right;
  padding-top: 0.3em;
}

.warning-dirty {
  padding-top: 0.3em;
}

.simple-button {
  min-height: 8em !important;
}

.icon-button {
  width: 9.8em !important;
  margin-Top: 0.2em !important;
}

.icon-buttons {
  width: 7.8em !important;
  margin-Top: 0.2em !important;
}

.slogan {
  color: rgb(0 148 170) !important;
}

.produktname {
  color: #fad64e;
}

.mobile-abwesenheit {
  padding-left: 0.1em !important;
  padding-right: 0.5em !important;
}

.mobile-padding-column {
  padding-right: 1em !important;
}

.row-button.mini {
  width: 4em !important;
  margin-top: -0.5em !important;
}

.no-file-icon {
  padding-left: 0.2em;
}

.table-button.mini {
  height: 2.2em !important;
}

.monthpicker-button {
  /* width: 6.0em !important; */
  width:fit-content !important;
}

.berufsschule {
  background: linear-gradient(135deg, lightgreen, white);
}

.halbertag {
  background: linear-gradient(135deg, gray, white);
}

.simpleUI-button-kommen {
  color: black !important;
  background-color: lightgreen !important;
  border: 4px solid darkgreen !important;
  min-height: 8em !important;
  font: bold !important;
}

.simpleUI-button-gehen {
  color: black !important;
  background-color: salmon !important;
  border: 4px solid darkred !important;
  min-height: 8em !important;
  font: bold !important;
}

.simpleUI-button-link {
  color: black !important;
  background-color: oldlace !important;
  border: 4px solid black !important;
  min-height: 8em !important;
  font: bold !important;
}

.simpleUI-button-montageA {
  color: black !important;
  background-color: oldlace !important;
  border: 3px solid darkgreen !important;
  min-height: 4em !important;
  font: bold !important;
  max-width: 49% !important;
  padding: 11px 5px 11px 5px !important;
  margin: unset !important;
}

.simpleUI-button-montageE {
  color: black !important;
  background-color: oldlace !important;
  border: 3px solid darkred !important;
  min-height: 4em !important;
  font: bold !important;
  max-width: 49% !important;
  padding: 11px 5px 11px 5px !important;
  margin: unset !important;
}

.simpleUI-button-kommenS {
  color: black !important;
  background-color: lightgreen !important;
  border: 3px solid darkgreen !important;
  min-height: 4em !important;
  font: bold !important;
  max-width: 49% !important;
  padding: 11px 5px 11px 5px !important;
  margin: unset !important;
}

.simpleUI-button-gehenS {
  color: black !important;
  background-color: salmon !important;
  border: 3px solid darkred !important;
  min-height: 4em !important;
  font: bold !important;
  max-width: 49% !important;
  padding: 11px 5px 11px 5px !important;
  margin: unset !important;
}

.simpleUI-grid {
  padding: 10px 5px 10px 5px !important;
}

.displayBlock {
  display: block !important;
}

.displayBlockNone {
  display: none !important;
}
/*
-------------------------------------------------
ABWESENHEITSTABELLE  
-------------------------------------------------
*/

.ui.table.abwesenheitenTabelle tr th:first-child { /* Nachname */
  max-width:200px; 
  word-wrap:break-word;
}
.ui.table.abwesenheitenTabelle tr th:nth-child(2) { /* Vorname */
  max-width: 200px;
  word-wrap: break-word;
}
.ui.table.abwesenheitenTabelle tr th:nth-child(3) { /* Personal-Nr */
  min-width: 57px; /* 57px allows for 4 digits to be displayed in one line */
  max-width: 57px;
  text-align: center;
}

@media screen and (max-width:1480px) {
.container_table {
    height: auto;
    max-height: 40em;
    width: auto;
    overflow-x: auto;
    overflow-y: auto;
  }

  /*Table-Header*/
  .ui.table.abwesenheitenTabelle tr th {
    position: sticky !important;
    left: 229px !important;
    z-index: 2;
    top: 0px;
  }

  .ui.table.abwesenheitenTabelle thead tr:first-child>th {
    position: sticky !important;
    top: 0 !important;
    z-index: 2;
  }

  .ui.table.abwesenheitenTabelle tr th:first-child {
    /* Nachname */
    position: sticky !important;
    left: 0px !important;
    z-index: 3;
  }

  .ui.table.abwesenheitenTabelle tr th:nth-child(2) {
    /* Vorname */
    position: sticky !important;
    left: 92px !important;
    z-index: 3;
  }

  .ui.table.abwesenheitenTabelle tr th:nth-child(3) {
    /* Personal-Nr */
    position: sticky !important;
    left: 174px !important;
    z-index: 3;
  }

    .ui.table.abwesenheitenTabelle tr td {
      position: sticky !important;
      left: 229px !important;
      top: 0;
    }
  
    .ui.table.abwesenheitenTabelle tr td:first-child {
      /* Nachname */
      position: sticky !important;
      left: 0px !important;
      top: 0;
    }
  
    .ui.table.abwesenheitenTabelle tr td:nth-child(2) {
      /* Vorname */
      position: sticky !important;
      left: 92px !important;
      top: 0;
    }
  
    .ui.table.abwesenheitenTabelle tr td:nth-child(3) {
      /* Personal-Nr */
      position: sticky !important;
      left: 174px !important;
      top: 0;
    }

}

.ui.table.abwesenheitenTabelle td.dummy {
  /* background-color: white; */
}

.ui.table.abwesenheitenTabelle tr td:first-child { /* Nachname */
  max-width: 200px;
}
.ui.table.abwesenheitenTabelle tr td:nth-child(2) { /* Vorname */
  max-width:200px;
}
.ui.table.abwesenheitenTabelle tr td:nth-child(3) { /* Personal-Nr */
  min-width: 57px; /* 57px allows for 4 digits to be displayed in one line */
  max-width: 57px;
  word-wrap: break-word;
}

.ui.table.abwesenheitenTabelle td{
  min-height: 42px;
}

/* Data of Table*/
table.ui.striped.table.abwesenheitenTabelle tbody>tr:nth-child(1n) {
  background-color: white;
}

table.ui.striped.table.abwesenheitenTabelle tbody>tr:nth-child(2n) {
  background-color: #f7f7ff;
}

.ui.selectable.table.abwesenheitenTabelle tbody tr:hover,
.ui.table.abwesenheitenTabelle tbody tr td.selectable:hover {
  background-color: #f8e8d8 !important;
}

td {
  background: inherit;
}

@media screen and (min-width:1480px){
.ui.table.abwesenheitenTabelle td{
  position: relative;
}
.abwesenheitenTabelle {
  position: relative;
}
.abwesenheitenTabelle thead{
  position:sticky;
  top:0;
  z-index:9;
}
}

/*
-------------------------------------------------
MONATSÜBERSICHT-TABELLE  
-------------------------------------------------
*/

.monatsTabelle {}

/*Table-Header*/
.ui.table.monatsTabelle tr th {
  position: sticky !important;
  left: 128px !important;
  z-index: 2;
  bottom: -1px;
}

.ui.table.monatsTabelle thead tr:first-child>th {
  position: sticky !important;
  top: 0 !important;
  z-index: 2;
}

.ui.table.monatsTabelle tr th:first-child {
  position: sticky !important;
  left: 0px !important;
  z-index: 3;
}

.ui.table.monatsTabelle tr th:nth-child(2) {
  position: sticky !important;
  left: 95px !important;
  z-index: 3;
}

.ui.table.monatsTabelle td.dummy {
  background-color: white;
}

/*Data of Table*/
.ui.table.monatsTabelle tr td {
  position: sticky !important;
  left: 128px !important;
  top: 0;
}

.ui.table.monatsTabelle tr td:first-child {
  position: sticky !important;
  left: 0px !important;
  top: 0;
  background: white !important;
}

.ui.table.monatsTabelle tr td:nth-child(2) {
  position: sticky !important;
  left: 95px !important;
  top: 0;
}

/*
-------------------------------------------------
LEISTUNGSNACHWEIS 
-------------------------------------------------
*/

.speech {
  border: 1px solid #ddd;
  width: 300px;
  padding: 0;
  margin: 0;
}

.speech input {
  border: 0;
  width: 240px;
  display: inline-block;
  height: 30px;
  font-size: 14px;
}

.speech img {
  float: right;
  width: 40px;
}

.speechBtn {
  width: 14em !important;
}

.containerSig {
  width: 100%;
  height: auto;
  top: 10%;
  left: 10%;
}

.sigContainer {
  border: solid black 1px;
  width: 100%;
  aspect-ratio: 2 / 1;
  margin: 0 auto;
  background-color: #fff;
}

@media screen and (min-width:1024px) {
  .sigContainer {
    aspect-ratio: 5.5/1;
  }
}

.sigPad {
  width: 100%;
  height: 100%;
  transform-origin: left top;
}

.buttonsSig {
  width: 100%;
  height: 3em;
  margin: 0 auto;
  background-color: #fff;
}

.sigButton {
  margin-top: 0.3em !important;
  margin-left: 0.3em !important;
  margin-right: 0.3em !important;
}

.sigImage {
  border: solid black 1px;
  background-size: 250px 60px;
  width: 250px;
  height: 60px;
  background-color: white;
  text-align: center;
}

.dictGrid {
  border-top: solid black 1px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.nachweisBorder {
  border-top: .2em solid #0094aa !important;
}
.buttonTooltip {
  width: 20px!important;
  height: 20px!important;
  margin: 0px!important;
  padding: 0px!important;
}