@page {
  size: A4;
  margin: 11mm 17mm 17mm 17mm;
}

@media print {
  html, body {
    margin: 0;
    -webkit-print-color-adjust: exact;
  }

  body {
    /*line-height: 1.1em !important;*/
    overflow: visible !important;
    color: black !important;
  }

  body,
  .ui.form .field > label,
  .ui.tiny.header,
  .ui.checkbox,
  .ui.list {
    font-size: 12px !important;
  }

  .print-only {
    display: block;
  }

  .hide-print {
    display: none !important;
  }

  .shadow-print {
  width: 100% !important;
  margin: 0;
  padding: 0;
  box-shadow: -1px 0 0 0 #d4d4d5 !important;
  }
  header.nav-menu .col-sm-2 {
    width: 100%;
    position: relative;
  }

  div.contact-info {
    float: right;
    text-align: right;
    width: 33%;
  }

  div.address-info {
    float: left;
    text-align: center;
    width: 33%;
  }

  .ui.header:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
    font-size: 14px;
  }

  .nav-menu .item.logo {
    float: left;
    width: 33%;
  }

  .nav-menu .ui.menu {
    padding: 0;
  }

  .ui.menu .item {
    position: absolute;
    bottom: 0;
  }

  .ui.menu:not(.vertical) .item.print-only {
    display: block;
    float: left;
    font-size: 10px;
  }

  div.item > div.search {
    display: none;
  }

  header.nav-menu .search {
    display: none;
  }

  header.nav-menu .item {
    display: none;
  }

  header.nav-menu .right {
    display: none;
  }

  .ui.menu .menu {
    display: none;
  }

  div.menu.ui.dropdown.mr-5-div {
    display: none;
  }

  .ui.celled.grid {
    width: 100%;
    margin: 1em 0;
    box-shadow: 0 0 0 2px #d4d4d5 !important;
  }

    .ui.celled.grid > .row {
      width: 100% !important;
      margin: 0;
      padding: 0;
      box-shadow: 0 -1px 0 0 #d4d4d5 !important;
    }

  body ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .page-break-before {
    page-break-before: always;
    padding-top: 30px;
  }

  li {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .ui .field, .form {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  div.basic {
    display:none;
  }

  .col-lg-1 {
    width: 8.33%;
    float: left;
    flex-basis: 8.33%;
    max-width: 8.33%;
  }

  .col-lg-2 {
    width: 16.66%;
    float: left;
    flex-basis: 16.66%;
    max-width: 16.66%;
  }

  .col-lg-3 {
    width: 25%;
    float: left;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    width: 33.33%;
    float: left;
    flex-basis: 33.33%;
    max-width: 33.33%;
  }

  .col-lg-5 {
    width: 41.66%;
    float: left;
    flex-basis: 41.66%;
    max-width: 41.66%;
  }

  .col-lg-6 {
    width: 50%;
    float: left;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    width: 58.33%;
    float: left;
    flex-basis: 58.33%;
    max-width: 58.33%;
  }

  .col-lg-8 {
    width: 66.66%;
    float: left;
    flex-basis: 66.66%;
    max-width: 66.66%;
  }

  .col-lg-9 {
    width: 75%;
    float: left;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    width: 83.33%;
    float: left;
    flex-basis: 83.33%;
    max-width: 83.33%;
  }

  .col-lg-11 {
    width: 91.66%;
    float: left;
    flex-basis: 91.66%;
    max-width: 91.66%;
  }

  .col-lg-12 {
    width: 100%;
    float: none;
  }

  .ui.segment {
    margin: 2px !important;
    padding: 2px 1em !important;
  }

  .ui.form input {
    margin: 0 !important;
  }

  .ui.input > input {
    padding: 2px !important;
  }

  .ui.form .field > label {
    color: black !important;
  }

  button , .row button.ui.button {
    display: none;
  }

  i.calendar {
    display: none !important;
  }

  .ui.warning.message {
    display: none;
  }

  .ui.cards > .card, .ui.card {
    display:block;
    float:none;
    width: 100% !important;
  }
}
